<template>
	<div class="floatingContactContainer" :class="{ open: isOpen }" v-if="!isMobile">
		<div class="floatingContactHeader cursorPointer" @click="isOpen = !isOpen">
			<font-awesome-icon :icon="['fab', 'whatsapp']" class="floatingContactIcon" />
			<span class="floatingContactLabel" :class="locale">{{ t("route.contact") }}</span>
		</div>

		<div class="floatingContactBody">
			<div class="floatingContactTitle">
				<div class="icon24">
					<svg class="icon-warning icon-md" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path
							d="M 12 2 C 9.2466037 2 6.7452927 3.1296817 4.9375 4.9375 L 3 3 L 3 8 L 8 8 L 6.34375 6.34375 C 7.5706232 5.1164712 9.1916201 4.2878598 11 4.0625 L 11 5 L 13 5 L 13 4.0625 C 16.611239 4.5159822 19.484018 7.3887611 19.9375 11 L 19 11 L 19 13 L 19.9375 13 C 19.484018 16.611239 16.611239 19.484018 13 19.9375 L 13 19 L 11 19 L 11 19.9375 C 7.3887611 19.484018 4.5159822 16.611239 4.0625 13 L 5 13 L 5 11 L 4.0625 11 L 3 11 L 2.0625 11 C 2.0295 11.329 2 11.662 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 6.486 17.514 2 12 2 z M 9.6875 8.71875 C 7.9975 8.71875 7.746 10.4095 7.75 10.8125 L 9 10.8125 C 9 10.6595 9.01025 9.75 9.65625 9.75 C 10.18825 9.75 10.25 10.37225 10.25 10.65625 C 10.25 11.00525 10.191 11.5875 7.875 14.3125 L 7.875 15.21875 L 11.625 15.21875 L 11.625 14.1875 L 9.46875 14.1875 C 10.93475 12.1355 11.5 11.59625 11.5 10.53125 C 11.5 10.25025 11.4625 8.71875 9.6875 8.71875 z M 14.4375 8.8125 L 12.28125 13.03125 L 12.34375 13.8125 L 14.4375 13.8125 L 14.4375 15.21875 L 15.6875 15.21875 L 15.6875 13.8125 L 16.25 13.8125 L 16.25 12.78125 L 15.6875 12.78125 L 15.6875 8.8125 L 14.4375 8.8125 z M 14.4375 10.6875 L 14.4375 12.78125 L 13.5 12.78125 L 14.4375 10.6875 z"
						></path>
					</svg>
				</div>
				<div class="titleText">
					<div class="highlight">24/7</div>
					<div>{{ t("common.memberService") }}</div>
				</div>
			</div>
			<div class="floatingContactContent">
				<template v-for="contact in contactList" :key="contact?.id">
					<UnifiedLink :link="contact?.redirectURL">
						<div class="floatingContactItem">
							<div class="floatContactItemIcon"><font-awesome-icon :icon="contact?.icon" /></div>
							<div class="floatContactItemTitle">{{ contact?.contactInformation }}</div>
						</div>
					</UnifiedLink>
				</template>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, computed, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useIsMobile } from "@/composables/useMobile";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const isMobile = useIsMobile();

const isOpen = ref(false);

const contactListIcon = [
	{
		icon: ["fab", "whatsapp"],
		name: "whatsapp",
	},
	{
		icon: ["fas", "comment-dots"],
		name: "livechat",
	},
	{
		icon: ["fab", "weixin"],
		name: "wechat",
	},
	{
		icon: ["fab", "telegram"],
		name: "telegram",
	},
	{
		icon: ["fab", "facebook"],
		name: "facebook",
	},
	{
		icon: ["fab", "instagram"],
		name: "instagram",
	},
	{
		icon: ["fas", "phone"],
		name: "phone",
	},
	{
		icon: ["fas", "square-phone"],
		name: "hotline",
	},
	{
		icon: ["fab", "line"],
		name: "line",
	},
	{
		icon: ["fas", "id-card"],
		name: "default",
	},
];

const contactList = computed(() => {
	return store.getters["brand/getBrand"].map((contact) => {
		const contactItem = contactListIcon.find((x) => x.name == contact.brandName.replace(" ", "").toLowerCase());
		if (contactItem != undefined) {
			contact.icon = contactItem.icon;
		} else {
			contact.icon = contactListIcon.find((x) => x.name == "default").icon;
		}
		return contact;
	});
});
</script>
<style lang="sass" scoped>
.floatingContactContainer
	position: fixed
	right: -13rem
	top: 10rem
	z-index: 40
	transition: 0.2s
	&.open
		right: 0
		.floatingContactHeader, .floatingContactBody
			box-shadow: var(--box-shadow)
.floatingContactHeader
	position: absolute
	top: 0
	right: 100%
	color: var(--text-regular)
	background: var(--color-accent)
	padding: 0.75rem 0.5rem 1rem
	display: flex
	align-items: center
	gap: 0.5rem
	font-weight: bold
	border-radius: 0.5rem 0 0 0.5rem
	writing-mode: vertical-rl
	text-orientation: mixed
	text-transform: uppercase
.floatingContactIcon
	font-size: 1.8rem
.floatingContactLabel
	&.zh
		letter-spacing: 0.5rem
.floatingContactBody
	background: var(--background-primary)
	width: 13rem
	min-height: 15rem
	max-height: 21rem
	border-radius: 0 0 0 0.5rem
	display: flex
	flex-direction: column
	align-items: start
	gap: 0.5rem
	overflow-y: auto
.floatingContactTitle
	display: flex
	align-items: center
	gap: 0.5rem
	background: var(--background-primary-3)
	width: 100%
	padding: 0.5rem 1rem
	.titleText
		font-size: 0.9em
		font-weight: var(--font-bold)
		line-height: normal
		.highlight
			color: var(--text-secondary)
			font-size: 1.6em
			line-height: 1.1em
.icon24
	width: 2.5rem
	fill: var(--color-secondary)
.floatingContactContent
	display: flex
	flex-direction: column
	width: 100%
	padding: 0 1rem 1rem
.floatingContactItem
	width: 100%
	display: flex
	gap: 0.5rem
	align-items: center
	padding-block: 0.25rem
	border-bottom: 1px solid var(--border-regular)
	&:hover
		font-weight: var(--font-medium)
.floatContactItemIcon
	text-align: center
	font-size: 1.8em
	width: 2rem
// .floatContactItemTitle
</style>
