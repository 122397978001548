<template>
	<b-modal
		id="quicktransfer-modal"
		class="defaultModal"
		centered
		:title="t('route.quicktransfer')"
		v-model="showModal"
		@show="initModal"
		@hide="hideModal"
		@hidden="endModal"
	>
		<form id="quickTransferForm" class="fieldContainer" @submit.prevent="onSubmit" novalidate>
			<!-- ================= MAIN WALLET ================= -->
			<div class="mainWalletContainer">
				<font-awesome-icon :icon="['fas', 'wallet']" class="walletIcon" />
				<div class="walletData">
					<div class="walletTitle">{{ t("account.mainWallet") }}</div>
					<div class="walletBalance">{{ currency }} {{ numberWithCommas(mainWalletBalance) }}</div>
				</div>
				<div class="depositIconContainer">
					<UnifiedLink :isRouteLink="deposit.isRouteLink" :link="deposit.link">
						<!-- <font-awesome-icon :icon="deposit.icon" class="depositIcon" /> -->
						<font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="depositIcon" />
						<div class="depositText">{{ t("route.deposit") }}</div>
					</UnifiedLink>
				</div>
			</div>
			<!-- ================= MAIN WALLET ================= -->

			<!-- ================= VENDOR WALLET ================= -->
			<div class="vendorWalletContainer">
				<font-awesome-icon :icon="['fas', 'coins']" class="walletIcon" />
				<div class="walletData">
					<div class="walletTitle">{{ vendor?.displayName }}</div>
					<div class="walletBalance">{{ currency }} {{ vendorBalance }}</div>
				</div>
			</div>
			<!-- ================= VENDOR WALLET ================= -->

			<!-- ================= INPUT ================= -->
			<div class="transferAmountContainer">
				<InputCurrency
					:label="t('account.transferAmount')"
					v-model="transferAmount"
					:currency="currency"
					:min="minAmount"
					:max="maxAmount"
					:disabled="transferAmountDisabled"
					:errors="v$.transferAmount.$errors"
				/>

				<InputSlider
					v-model="transferAmount"
					:min="minAmount"
					:max="maxAmount"
					:disabled="transferAmountDisabled"
				/>
			</div>

			<div class="promoContainer">
				<InputSelect
					:label="t('account.promotion')"
					v-model="selectedPromotion"
					:options="promotionList"
					:placeholder="t('account.NoApplyBonus')"
					:disabled="promotionDisabled"
				/>
			</div>
			<!-- ================= INPUT ================= -->
		</form>

		<!-- ================= FOOTER ================= -->

		<template #footer>
			<button
				type="button"
				class="secondaryButton playButton"
				:disabled="vendor?.maintenance"
				@click="onSubmit({ skipTransfer: true })"
			>
				{{ vendor?.maintenance ? t("common.underMaintenance") : t("games.play") }}
			</button>

			<button type="submit" form="quickTransferForm" class="mainButton" :disabled="submitButtonDisabled">
				{{ vendor?.maintenance ? t("common.underMaintenance") : t("common.transferAndPlay") }}
			</button>
		</template>

		<!-- ================= FOOTER ================= -->

		<ConfirmationDialog ref="confirmationDialog">
			<div class="confirmationHeader">
				{{ selectedPromotion?.title }}
			</div>
			<div class="confirmationMessage">{{ t("common.youAreGoingToClaim") }} {{ t("common.areYouSure") }}</div>
		</ConfirmationDialog>
	</b-modal>
</template>

<script setup>
import { ref, computed, nextTick, defineEmits, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "vue-toastification";
import { useVuelidate } from "@vuelidate/core";
import { minValue, maxValue, numeric, required, helpers } from "@vuelidate/validators";
import { country, countryData, numberWithCommas } from "@/composables/useCountry";
import { isLogin } from "@/composables/useAuth";
import { languageCodeToId } from "@/composables/useCulture";
import { routeData } from "@/constant";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import { GameType } from "@/common/GameType";
import {
	loginPlaytechLive,
	calloutLoginPlaytech,
	launchPlaytechLiveCasino,
} from "@/composables/usePlaytechCasinoLaunchGame";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import InputCurrency from "@/components/dynamic/Inputs/InputCurrency.vue";
import InputSlider from "@/components/dynamic/Inputs/InputSlider.vue";
import InputSelect from "@/components/dynamic/Inputs/InputSelect.vue";
import ConfirmationDialog from "@/components/dynamic/ConfirmationDialog.vue";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const $loading = useLoading();
const toast = useToast();

const deposit = routeData.find((item) => item.title == "deposit");

// ================= Vendors Start =================

const vendorId = computed(() => route.query?.vendorId);
const vendor = computed(() => vendorList.value.find((vendor) => vendor?.id == vendorId.value));
const vendorBalance = computed(() => (vendor.value?.memberBalance || 0).toFixed(2));
const vendorList = computed(() => store.getters["vendors/getActiveMemberVendor"]());

const isPlayTech = computed(() => vendor.value?.name.includes("PLAYTECH"));

// ================= Vendors End =================

// ================= Transferable Vendors Start =================

const transferableVendorList = computed(() => store.getters["vendors/getTransferableVendor"]);
const fetchTransferableVendor = async () => {
	queueFetch();
	await store
		.dispatch("vendors/fetchTransferableVendor", {
			vendorName: "MAIN",
			bonusId: null,
		})
		.finally(() => {
			completeFetch();
		});
};

const vendorTransferable = computed(() =>
	transferableVendorList.value.some((transferableVendor) => transferableVendor.vendorName === vendor.value?.name)
);

// ================= Transferable Vendors End =================

// ================= Member Start =================

const member = computed(() => store.getters["member/getMember"]);
const autoTransfer = computed(() => member.value?.autoTransfer);

// ================= Member End =================

// ================= Balance Start =================

const currency = computed(() => countryData[country].CURRENCY);
const mainWalletBalance = computed(() => member.value?.balance || 0);

// ================= Balance End =================

// ================= Input Start =================

const transferAmount = ref(0);
const transferAmountDisabled = computed(
	() => vendor.value?.maintenance || !vendorTransferable.value || selectedPromotion.value?.isDirectClaim
);

const minAmount = computed(() => {
	if (selectedPromotion.value?.isDirectClaim) return 0;
	if (selectedPromotion.value?.id) return Math.max(selectedPromotion.value.minDeposit, 0);
	return 0;
});
const maxAmount = computed(() => (selectedPromotion.value?.isDirectClaim ? 0 : mainWalletBalance.value));

const submitButtonDisabled = computed(
	() => vendor.value?.maintenance || (!selectedPromotion.value?.isDirectClaim && transferAmount.value <= 0)
);

// ================= Input End =================

// ================= Promotion Start =================

const selectedPromotion = ref(null);
const promotionList = computed(() => store.getters["bonus/getMemberPreBonusList"]);
watch(selectedPromotion, (newPromotion) => {
	if (newPromotion?.isDirectClaim) transferAmount.value = 0;
});
const fetchMemberPreBonusList = async () => {
	await store.dispatch("bonus/fetchMemberPreBonusList", {
		langId: languageCodeToId[locale.value],
		vendorToId: vendorId.value,
		bonusId: null,
		keepStore: true,
	});
};
const promotionDisabled = computed(() => vendor.value?.maintenance || !vendorTransferable.value);

// ================= Promotion End =================

// ================= Validation Start =================

const rules = computed(() => ({
	transferAmount: {
		required: helpers.withMessage(() => t("validation.required"), required),
		numeric: helpers.withMessage(() => t("validation.numeric"), numeric),
		minValue: helpers.withMessage(
			({ $params }) => t("validation.minAmount", { minAmount: $params.min }),
			minValue(minAmount.value)
		),
		maxValue: helpers.withMessage(
			({ $params }) => t("validation.maxAmount", { maxAmount: $params.max }),
			maxValue(maxAmount.value)
		),
	},
}));

const v$ = useVuelidate(rules, { transferAmount });

// ================= Validation End =================

// ================= Confirmation Start =================

const confirmationDialog = ref(null);

const openConfirmation = async () => {
	const userInput = await confirmationDialog.value?.openConfirmationDialog();
	return userInput;
};

// ================= Confirmation End =================

// ================= Submit Start =================

const onSubmit = async (options = { skipTransfer: false }) => {
	const validity = await v$.value.$validate();
	if (!validity) return;

	if (selectedPromotion.value) {
		const userConfirm = await openConfirmation();
		if (!userConfirm) return;
	}

	const loader = $loading.show();

	if (!isPlayTech.value) store.dispatch("window/openNewWindow", { windowName: "game_window", loading: true });
	if (!options.skipTransfer && (transferAmount.value > 0 || selectedPromotion.value?.isDirectClaim)) {
		await transferBalance();
		fetchMemberBalance();
		updateVendorBalance(vendor.value?.vendorAccountId);
	}
	await redirectToGame();

	loader.hide();
};

const transferBalance = async () => {
	await store.dispatch("payment/transferBalance", {
		vendorFrom: "MAIN",
		vendorTo: vendor.value?.name,
		amount: transferAmount.value,
		bonusID: selectedPromotion.value?.id || undefined,
		promoCode: null,
	});
	// .then((res) => {
	// 	if (res.succeeded) {
	// 		toast.success(t("toast.transferSuccess"), toastOptionSuccess);
	// 	} else {
	// 		toast.error(t("toast.transferFailed", { error: res.errorMsg }), toastOptionError);
	// 	}
	// })
	// .catch((err) => {
	// 	toast.error(t("toast.transferFailed", { error: err.response?.data.title }), toastOptionError);
	// });
};

const fetchMemberBalance = async () => {
	queueFetch();
	await store.dispatch("member/fetchMember").finally(() => completeFetch());
};

const updateVendorBalance = async (vendorAccountId) => {
	queueFetch();
	const res = await store
		.dispatch("memberAccount/fetchVendorBalance", {
			vendorAccountId: vendorAccountId,
			isFromDB: false,
		})
		.finally(() => completeFetch());
	return res?.balance;
};

const redirectToGame = async () => {
	if (isPlayTech.value) {
		await playtechLaunchGame();
		hideModal();
		return;
	}

	store.dispatch("window/setNewWindow", {
		windowName: "game_window",
		url: decodeURIComponent(route.query?.redirectUrl),
	});
	hideModal();
};

const playtechLaunchGame = async () => {
	return await store
		.dispatch("memberAccount/fetchMemberAccount", { vendorAccountId: vendor.value?.vendorAccountId })
		.then((res) => {
			loginPlaytechLive(
				{ loginid: res.vendorGameId, password: res.vendorPassword },
				route.query?.gameType,
				route.query?.gameCode
			);
		});
};

// ================= Submit End =================

// ================= API Start =================

const queueFetch = () => store.dispatch("progressTracker/queueTask");
const completeFetch = () => store.dispatch("progressTracker/completeTask");

const fetchMemberActiveVendorList = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchMemberActiveVendorList").finally(() => completeFetch());
};

// ================= API End =================

// ================= Modal Control Start =================

const MODAL_NAME = "quicktransfer";
const showModal = ref(false);

const setModalParam = () => {
	router.replace({ query: { ...route.query, modal: MODAL_NAME } });
};

const unsetModalParam = () => {
	const { modal, redirectUrl, gameType, ...params } = route.query;
	router.replace({ query: params });
};

watch(route, async (newRoute, oldRoute) => {
	if (newRoute.query?.modal != MODAL_NAME) {
		showModal.value = false;
		return;
	}
	await nextTick();
	if (isLogin.value) showModal.value = true;
	else unsetModalParam();
});

const hideModal = () => {
	// redirectToGame();
	unsetModalParam();
	showModal.value = false;
};

const initModal = async () => {
	setModalParam();

	transferAmount.value = 0;
	selectedPromotion.value = null;
	fetchMemberBalance();
	fetchMemberActiveVendorList();
	fetchTransferableVendor();
	fetchMemberPreBonusList();

	return;
};

const endModal = () => {
	return;
};

// ================= Modal Control End =================

// ================= Lifecycle Start =================

// ================= Lifecycle End =================
</script>

<style scoped lang="sass">
.quickTransferBody
	display: flex
	flex-direction: column
	gap: 1rem
.mainWalletContainer, .vendorWalletContainer
	display: flex
	align-items: center
	gap: 1.5rem
	height: 6rem
	padding-inline: 1.5rem
	border-radius: 8px
.mainWalletContainer
	background: var(--color-accent)
.vendorWalletContainer
	background: var(--color-secondary)
	color: var(--text-inverted)
.walletIcon
	font-size: 3em
.walletTitle
	font-size: 1.5em
	font-weight: var(--font-bold)
.walletBalance
	font-size: 1.2em
	font-weight: var(--font-medium)
.depositIconContainer
	margin-left: auto
	text-align: center
.depositIcon
	font-size: 1.2rem
.depositText
	font-weight: var(--font-medium)
.playButton
	color: var(--text-regular)
	font-weight: var(--font-bold)
	background: var(--background-primary-3)
.confirmationHeader
	color: var(--text-secondary)
	font-weight: var(--font-bold)
	font-size: 1.5em

@media (max-width: 767px)
	.mainWalletContainer, .vendorWalletContainer
		gap: 1rem
		height: 6rem
		padding-inline: 1.8rem
	.walletIcon
		font-size: 2.5em
	.walletTitle
		font-size: 1.5em
	.walletBalance
		font-size: 1.1em
	.depositIcon
		font-size: 1.7rem
	.depositText
		display: none
</style>
