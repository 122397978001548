<template>
	<nav>
		<div class="navList container">
			<div class="navListLeft">
				<!-- HOME -->
				<UnifiedLink :isRouteLink="home.isRouteLink" :link="home.link">
					<div class="navItem">
						<font-awesome-icon class="navIcon homeIcon" :icon="home.icon" />
					</div>
				</UnifiedLink>

				<!-- DOWNLOAD -->
				<UnifiedLink :isRouteLink="download.isRouteLink" :link="download.link">
					<div class="navItem">
						<font-awesome-icon class="navIcon downloadIcon" :icon="download.icon" />
						<span class="navText">{{ t(`route.${download.title}`) }}</span>
					</div>
				</UnifiedLink>

				<!-- GAMES -->
				<template v-for="categoryItem in categoryList" :key="categoryItem">
					<template v-if="categoryItem.nav">
						<UnifiedLink :isRouteLink="categoryItem.nav.isRouteLink" :link="categoryItem.nav.link">
							<div class="navItem">
								<span class="navText">{{ t(`route.${categoryItem.nav.title}`) }}</span>
							</div>
						</UnifiedLink>
					</template>
				</template>

				<!-- PROMOTION -->
				<UnifiedLink :isRouteLink="promotion.isRouteLink" :link="promotion.link">
					<div class="navItem">
						<font-awesome-icon class="navIcon promotionIcon" :icon="promotion.icon" />
						<span class="navText">{{ t(`route.${promotion.title}`) }}</span>
					</div>
				</UnifiedLink>

				<!-- VIP -->
				<UnifiedLink :isRouteLink="vip.isRouteLink" :link="vip.link" :validateLogin="vip.validateLogin">
					<div class="navItem">
						<font-awesome-icon class="navIcon vipIcon" :icon="vip.icon" />
						<span class="navText">{{ t(`route.${vip.title}`) }}</span>
					</div>
				</UnifiedLink>

				<!-- REFERRAL -->
				<UnifiedLink
					v-if="isFeatureEnabled['REFERRAL']"
					:isRouteLink="referral.isRouteLink"
					:link="referral.link"
					:validateLogin="referral.validateLogin"
				>
					<div class="navItem">
						<font-awesome-icon class="navIcon referralIcon" :icon="referral.icon" />
						<span class="navText">{{ t(`route.${referral.title}`) }}</span>
					</div>
				</UnifiedLink>
			</div>
		</div>
	</nav>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { isFeatureEnabled, routeData } from "@/constant";
import { useI18n } from "vue-i18n";
import { isLogin } from "@/composables/useAuth";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import { GameType } from "@/common/GameType";
import { useLoading } from "vue-loading-overlay";
import { useIsMobile } from "@/composables/useMobile";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError, toastOptionWarning } from "@/composables/useToastOptions";

const { t, locale } = useI18n();
const imgUrlDomain = process.env.VUE_APP_Media_Domain;
const store = useStore();
const $loading = useLoading();
const isMobile = useIsMobile();
const router = useRouter();
const route = useRoute();
const toast = useToast();

// ================= Nav Start =================

// Common nav
const home = routeData.find((item) => item.title == "home");
const download = routeData.find((item) => item.title == "download");
const promotion = routeData.find((item) => item.title == "promotion");
const vip = routeData.find((item) => item.title == "vip");
const referral = routeData.find((item) => item.title == "referral");

// Games nav
const slots = routeData.find((item) => item.title == "slots");
const casino = routeData.find((item) => item.title == "casino");
const fishing = routeData.find((item) => item.title == "fishing");
const sports = routeData.find((item) => item.title == "sports");

// ================= Category Start =================

const categoryList = computed(() =>
	store.getters["vendors/getCategoryList"].map((category) => {
		const title = category.name?.split(" ")?.pop()?.toLowerCase()?.replaceAll(" ", "");
		return {
			...category,
			nav: routeData.find((route) => route.title == title),
		};
	})
);

// ================= Category End =================

// ================= Game Start =================

// ================= Game End =================

// ================= Nav End =================
</script>

<style scoped lang="sass">
nav
	background: var(--color-accent)
.navList
	display: flex
	justify-content: center
	align-items: center
	flex-wrap: wrap
.navListLeft, .navListRight
	display: flex
	align-items: center
.navItem
	position: relative
	display: flex
	align-items: center
	gap: 0.5rem
	font-size: 1.05em
	font-weight: var(--font-medium)
	line-height: 1.1rem
	text-wrap: nowrap
	padding: 0.8rem 1.4rem
	overflow: hidden
	text-transform: uppercase
	color: var(--text-regular)
	&::after
		position: absolute
		content: ""
		width: 100%
		height: 4px
		background: var(--color-secondary)
		bottom: 0
		left: 50%
		transform: translate(-50%, 100%)
		// border-radius: 4px 4px 0 0
		transition: 0.2s
	&:hover
		color: var(--text-secondary)
		&::after
			transform: translate(-50%)
		.downloadIcon
			animation-name: dropFromTop
			animation-duration: 0.25s
			animation-timing-function: ease-in-out
		.promotionIcon
			animation-name: jump
			animation-duration: 0.35s
			animation-timing-function: linear
		.vipIcon
			animation-name: spinY
			animation-duration: 0.5s
			animation-timing-function: ease-in-out
		.referralIcon
			animation-name: danceBottom
			animation-duration: 0.5s
			animation-timing-function: ease-in-out
.navText
	line-height: normal
.navIcon
	&.downloadIcon
		font-size: 1.15em
.router-link-active .navItem
	color: var(--text-secondary)
	&::after
		transform: translate(-50%)
.homeIcon
	font-size: 1.1rem
</style>
