<template>
	<footer>
		<div class="theFooterContainer container">
			<!-- MAIN CONTENT -->
			<div class="footerColumns">
				<div class="footerColumn">
					<!-- GAMING LICENSE -->
					<div class="columnTitle">
						{{ t("footer.gamingLicense") }}
						<font-awesome-icon :icon="['fas', 'circle-check']" class="checkIcon" />
					</div>
					<div class="columnContent gamingLicenseDetails">
						<img
							class="footerImage gamingCuracao"
							:src="imgUrlDomain + 'moon99/assets/images/Footer/gaming_curacao.png'"
						/>
						{{ t("footer.gameLicensesDetails") }}
					</div>
					<!-- GAMING LICENSE -->
				</div>

				<div class="footerColumn">
					<!-- CERTIFICATION -->
					<div class="columnTitle">
						{{ t("footer.certification") }}
					</div>
					<div class="columnContent">
						<img class="footerImage bmm" :src="imgUrlDomain + 'moon99/assets/images/Footer/bmm-logo.png'" />
						<img class="footerImage" :src="imgUrlDomain + 'moon99/assets/images/Footer/itechlab.png'" />
						<img class="footerImage" :src="imgUrlDomain + 'moon99/assets/images/Footer/GLI.png'" />
						<img class="footerImage tst" :src="imgUrlDomain + 'moon99/assets/images/Footer/TST.png'" />
						<img class="footerImage" :src="imgUrlDomain + 'moon99/assets/images/Footer/godaddy.png'" />
					</div>
					<!-- CERTIFICATION -->

					<!-- PAYMENT METHOD -->
					<div class="columnTitle">
						{{ t("footer.paymentMethod") }}
					</div>
					<div class="columnContent">
						<img
							class="footerImage"
							:src="imgUrlDomain + 'moon99/assets/images/Footer/PaymentMethod/rapidpay.png'"
						/>
						<img
							class="footerImage dgpay"
							:src="imgUrlDomain + 'moon99/assets/images/Footer/PaymentMethod/dgpay.png'"
						/>
						<img
							class="footerImage surepay"
							:src="imgUrlDomain + 'moon99/assets/images/Footer/PaymentMethod/surepay.png'"
						/>
					</div>
					<!-- PAYMENT METHOD -->
				</div>

				<div class="footerColumn mobileGrid">
					<!-- SECURITY -->
					<div class="columnTitle">
						{{ t("footer.security") }}
					</div>
					<div class="columnContent">
						<img
							class="footerImage iovation"
							:src="imgUrlDomain + 'moon99/assets/images/Footer/iovation-logo.png'"
						/>
						<img
							class="footerImage threatmetrix"
							:src="imgUrlDomain + 'moon99/assets/images/Footer/threatmetrix.png'"
						/>
					</div>
					<!-- SECURITY -->

					<!-- RESPONSIBLE GAMING -->
					<div class="columnTitle">
						{{ t("footer.responsibleGaming") }}
					</div>
					<div class="columnContent">
						<img class="footerImage" :src="imgUrlDomain + 'moon99/assets/images/Footer/18-above.svg'" />
						<img class="footerImage" :src="imgUrlDomain + 'moon99/assets/images/Footer/gamcare.svg'" />
						<img class="footerImage" :src="imgUrlDomain + 'moon99/assets/images/Footer/bga.svg'" />
					</div>
					<!-- RESPONSIBLE GAMING -->
				</div>
			</div>
			<!-- MAIN CONTENT -->

			<!-- FOLLOW US -->
			<div class="footerSocials" v-if="contactList.length">
				<template v-for="contact in contactList" :key="contact?.id">
					<UnifiedLink :link="contact?.redirectURL">
						<div class="contactItem">
							<img :src="contact?.brandURL" :alt="contact?.brandName" class="contactIcon" />
						</div>
					</UnifiedLink>
				</template>
			</div>
			<!-- FOLLOW US -->

			<hr />

			<!-- SEO -->

			<div class="footerSeoContainer">
				<div class="footerSeoTitle">
					<h1>
						{{ t("common.brandName") }}
					</h1>
				</div>
				<div class="footerSeoDetails">
					<ul>
						<li>
							<h2>{{ t("about.seo.title1") }}</h2>
							<p>{{ t("about.seo.detail1") }}</p>
						</li>
						<li>
							<h2>{{ t("about.seo.title2") }}</h2>
							<p>{{ t("about.seo.detail2") }}</p>
						</li>
						<li>
							<h2>{{ t("about.seo.title3") }}</h2>
							<p>{{ t("about.seo.detail3") }}</p>
						</li>
						<li>
							<h2>{{ t("about.seo.title4") }}</h2>
							<p>{{ t("about.seo.detail4") }}</p>
						</li>
					</ul>
					<ul>
						<li>
							<h2>{{ t("about.seo.title5") }}</h2>
							<p>{{ t("about.seo.detail5") }}</p>
						</li>
						<li>
							<h2>{{ t("about.seo.title6") }}</h2>
							<p>{{ t("about.seo.detail6") }}</p>
						</li>
						<li>
							<h2>{{ t("about.seo.title7") }}</h2>
							<p>{{ t("about.seo.detail7") }}</p>
						</li>
						<li>
							<h2>{{ t("about.seo.title8") }}</h2>
							<p>{{ t("about.seo.detail8") }}</p>
						</li>
					</ul>
				</div>
			</div>

			<!-- SEO -->

			<hr />

			<!-- BOTTOM -->
			<div class="footerBottom">
				<!-- LINKS -->
				<div class="footerLinks">
					<UnifiedLink :isRouteLink="aboutUs.isRouteLink" :link="aboutUs.link">
						<div class="footerLink"><font-awesome-icon :icon="aboutUs.icon" />{{ t("route.aboutus") }}</div>
					</UnifiedLink>
					<UnifiedLink :isRouteLink="contactUs.isRouteLink" :link="contactUs.link">
						<div class="footerLink">
							<font-awesome-icon :icon="contactUs.icon" />{{ t("route.contact") }}
						</div>
					</UnifiedLink>
					<UnifiedLink :isRouteLink="terms.isRouteLink" :link="terms.link">
						<div class="footerLink"><font-awesome-icon :icon="terms.icon" />{{ t("route.terms") }}</div>
					</UnifiedLink>
					<UnifiedLink :isRouteLink="privacyPolicy.isRouteLink" :link="privacyPolicy.link">
						<div class="footerLink">
							<font-awesome-icon :icon="privacyPolicy.icon" />{{ t("route.privacypolicy") }}
						</div>
					</UnifiedLink>
				</div>
				<!-- LINKS -->

				<!-- COPYRIGHT -->
				<div class="footerCopyright">
					{{ t("footer.copyright") }}
				</div>
				<!-- COPYRIGHT -->
			</div>
			<!-- BOTTOM -->
		</div>
	</footer>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { routeData } from "@/constant";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";

const { t, locale } = useI18n();
const store = useStore();

const imgUrlDomain = process.env.VUE_APP_Media_Domain;

const aboutUs = routeData.find((item) => item.title == "aboutus");
const contactUs = routeData.find((item) => item.title == "contact");
const terms = routeData.find((item) => item.title == "terms");
const privacyPolicy = routeData.find((item) => item.title == "privacypolicy");

const contactListIcon = [
	{
		icon: ["fab", "whatsapp"],
		name: "whatsapp",
	},
	{
		icon: ["fas", "comment-dots"],
		name: "liveChat",
	},
	{
		icon: ["fab", "weixin"],
		name: "weChat",
	},
	{
		icon: ["fab", "telegram"],
		name: "telegram",
	},
	{
		icon: ["fas", "phone"],
		name: "phone",
	},
	{
		icon: ["fas", "square-phone"],
		name: "hotline",
	},
	{
		icon: ["fab", "line"],
		name: "line",
	},
	{
		icon: ["fas", "id-card"],
		name: "default",
	},
];

const contactList = computed(() => {
	return store.getters["brand/getBrand"];
});
</script>

<style scoped lang="sass">
footer
	margin-top: 9rem
	background: var(--background-footer)
	color: var(--text-inverted)
	box-shadow: var(--box-shadow)
.theFooterContainer
	padding-block: 2rem 3rem
	@media (max-width: 767px)
		padding: 0 1rem 1rem !important
.footerColumns
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	min-height: 7rem
	@media (max-width: 767px)
		flex-direction: column
		flex-wrap: nowrap
		align-items: start
.footerColumn
	display: flex
	flex-direction: column
	align-items: start
	@media (max-width: 767px)
		align-items: start
		width: 100%

.mobileGrid
	@media (max-width: 767px)
		display: grid
		grid-template-rows: 1fr 1fr
		grid-auto-flow: column

.columnTitle
	color: var(--text-secondary)
	font-weight: var(--font-medium)
	font-size: 1em
	.checkIcon
		color: var(--success)
	@media (max-width: 767px)
		font-size: 0.9em
		margin-top: 1.5rem

.columnContent
	display: flex
	align-items: center
	flex-wrap: wrap
	gap: 1rem
	padding-block: 0.75rem 2.4rem
	&.gamingLicenseDetails
		max-width: 20vw
		font-size: 0.8em
	@media (max-width: 767px)
		width: 100%
		justify-content: start
		gap: 0.5rem
		padding-block: 0.25rem
		&.gamingLicenseDetails
			max-width: unset
.footerSocials
	display: flex
	justify-content: end
	align-items: center
	gap: 1rem
	font-size: 1.8em
	a
		color: var(--text-inverted)
	// .facebook:hover
	// 	color: #4267b2
	// .telegram:hover
	// 	color: #0088cc
	// .youtube:hover
	// 	color: #ff0000
	// .twitter:hover
	// 	color: #1da1f2
	.contactIcon
		width: 1.8rem
		height: 1.8rem
		object-fit: contain
		filter: grayscale(1)
		-webkit-filter: grayscale(1)
		&:hover
			filter: grayscale(0)
			-webkit-filter: grayscale(0)
	@media (max-width: 767px)
		justify-content: start
		margin-block: 1rem
.footerImage
	object-fit: contain
	height: 1.8rem
	&.gamingCuracao
		height: 2.7rem
	&.bmm
		height: 1rem
	&.tst
		height: 2.2rem
	&.iovation
		height: 1.1rem
	&.threatmetrix
		height: 2.2rem
		width: 7rem
	&.dgpay
		height: 1.8rem
	&.surepay
		height: 2.3rem
.footerSeoContainer
	margin-block: 1rem
.footerSeoTitle
	font-weight: var(--font-medium)
	margin-bottom: 1.5rem
	@media (max-width: 767px)
		margin-bottom: 0.75rem
	h1
		font-size: 1em
.footerSeoDetails
	font-size: 0.8em
	color: var(--text-light)
	display: flex
	gap: 3rem
	ul
		list-style-type: none
		padding: 0
		margin: 0
		width: calc(50% - 1.5rem)
		@media (max-width: 767px)
			width: 100%
	li
		margin-bottom: 1.5rem
		@media (max-width: 767px)
			margin-bottom: 0.5rem
	h2
		font-size: 1em
		font-weight: var(--font-bold)
		margin: 0
	p
		margin: 0
		padding: 0
		text-align: justify
	@media (max-width: 767px)
		text-align: justify
		flex-direction: column
		gap: 0
.footerBottom
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	align-items: center
	@media (max-width: 767px)
		justify-content: center
		row-gap: 1rem
		margin-block: 1rem
.footerLinks
	display: flex
	align-items: center
	.unifiedLink
		&:last-child
			.footerLink
				border-right: unset
	@media (max-width: 767px)
		gap: 1rem
.footerLink
	display: flex
	align-items: center
	gap: 0.5rem
	font-size: 0.9em
	padding: 0 1rem
	border-right: 1px solid var(--border-light)
	color: var(--text-inverted)
	svg
		font-size: 1.2em
		vertical-align: text-bottom
	&:hover
		color: var(--color-secondary)
	@media (max-width: 767px)
		font-size: 0.7em
		padding: 0
		border: none
		gap: 0.25rem

.footerCopyright
	font-size: 0.8em
</style>
