import { ActionTree } from "vuex";
import { getGlobalSetting } from "@/network/GlobalSettingService/GlobalSetting";
import { GlobalSettingStateInterface } from "./state";
import { RootState } from "@/store/state";
import { GlobalSettingMutations } from "./mutations";
import moment from "moment";

export const actions: ActionTree<GlobalSettingStateInterface, RootState> = {
	async fetchGlobalSetting(context, payload) {
		const res = await getGlobalSetting(payload.remark);
		context.commit(GlobalSettingMutations.SET_GLOBAL_SETTING, res);
		return res;
	},
	fetchCurrentTime(context, payload) {
		const dateNow = moment(new Date()).format("DD/M/YYYY HH:mm:ss ([GMT]ZZ)");
		context.commit(GlobalSettingMutations.SET_CURRENT_TIME, dateNow);
		setTimeout(() => context.dispatch("fetchCurrentTime"), 1000);
	},
};
