import { GlobalSettingStateInterface } from "./state";
import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";
import { MutationTree } from "vuex";

export enum GlobalSettingMutations {
  SET_GLOBAL_SETTING = "SET_GLOBAL_SETTING",
  SET_CURRENT_TIME = "SET_CURRENT_TIME",
}

export const mutations: MutationTree<GlobalSettingStateInterface> = {
  [GlobalSettingMutations.SET_GLOBAL_SETTING](
    state,
    payload: GlobalSettingResponse[]
  ) {
    state.globalSetting.push(...payload);
  },

  [GlobalSettingMutations.SET_CURRENT_TIME](state, payload: string) {
		state.currentTime = payload;
	},
};
