<template>
	<!-- ================= STEP 1 ================= -->
	<b-modal
		id="login-modal"
		class="defaultModal"
		centered
		hide-footer
		:title="t('route.login')"
		v-model="showModal"
		@show="initModal"
		@hide="hideModal"
		:model-ok="false"
	>
		<div class="registerContainer">
			<div class="registerMessage">
				{{ t("common.areYouNewHere") }}
			</div>
			<UnifiedLink :isModal="register.isModal" :modalName="register.modalName" :guestOnly="register.guestOnly">
				<button class="mainButton registerButton">
					{{ t(`route.${register.title}`) }}
				</button>
			</UnifiedLink>
		</div>

		<form v-if="currentProgress == 1" class="fieldContainerModal" @submit.prevent="onSubmitLogin" novalidate>
			<InputPhone
				v-model="phoneNo"
				:countryCode="countryCode"
				:label="t('common.phoneNumber')"
				:required="true"
				:errors="v$.account.phoneNo.$errors"
			/>

			<InputPassword
				v-model="password"
				:label="t('common.password')"
				:required="true"
				:errors="v$.account.password.$errors"
			/>

			<div class="buttonsContainer">
				<UnifiedLink :isModal="forgotPassword.isModal" :modalName="forgotPassword.modalName">
					<button type="button" class="forgotPasswordButton unstyledButton">
						{{ t("route.forgotpassword") }}
					</button>
				</UnifiedLink>

				<button type="submit" class="mainButton">
					{{ t("route.login") }}
				</button>
			</div>
		</form>

		<!-- ================= STEP 1 ================= -->

		<!-- ================= STEP 2 ================= -->

		<form v-if="currentProgress == 2" class="fieldContainerModal" @submit.prevent="onSubmitTac" novalidate>
			<InputOtp
				v-model="tacCode"
				:label="t('common.verificationCode')"
				:required="true"
				:errors="v$.tac.tacCode.$errors"
			/>

			<div class="buttonsContainer">
				<button type="button" class="backButton unstyledButton" @click="updateProgress('prev')">
					{{ t("common.back") }}
				</button>

				<!-- "key" directive helps refresh the ".once" event modifier -->
				<!-- once the coundown starts -->
				<button
					type="button"
					class="secondaryButton"
					:disabled="timeRemaining"
					@click.once="requestTAC(true)"
					:key="timeRemaining"
				>
					{{ timeRemaining ? t("common.resendInSeconds", { time: timeRemaining }) : t("common.resendTac") }}
				</button>

				<button type="submit" class="mainButton">
					{{ t("common.submit") }}
				</button>
			</div>
		</form>

		<!-- ================= STEP 2 ================= -->

		<!-- ================= FOOTER ================= -->

		<!-- <template #footer> </template> -->

		<!-- ================= FOOTER ================= -->
	</b-modal>
</template>

<script setup>
import { ref, computed, nextTick, defineEmits, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "vue-toastification";
import { useVuelidate } from "@vuelidate/core";
import { minLength, maxLength, numeric, required, helpers } from "@vuelidate/validators";
import { routeData } from "@/constant";
import { country, countryData } from "@/composables/useCountry";
import { isLogin, authField } from "@/composables/useAuth";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import InputPhone from "@/components/dynamic/Inputs/InputPhone.vue";
import InputPassword from "@/components/dynamic/Inputs/InputPassword.vue";
import InputOtp from "@/components/dynamic/Inputs/InputOtp.vue";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
// import CustomBottomModal from "@/components/dynamic/CustomBottomModal.vue";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const $loading = useLoading();
const toast = useToast();

const loginModalName = "login-modal";
const emit = defineEmits(["closed"]);
// ================= Links Start =================

const register = routeData.find((item) => item.title == "register");
const forgotPassword = routeData.find((item) => item.title == "forgotpassword");

// ================= Links End =================

// ================= Progress Start =================

const currentProgress = ref(1);
const progressList = ref([
	{
		step: 1,
		name: t("route.login"),
	},
	{
		step: 2,
		name: t("common.tac"),
	},
]);
const updateProgress = (direction) => {
	if (direction == "next" && currentProgress.value < progressList.value.length) currentProgress.value += 1;
	if (direction == "prev" && currentProgress.value > 1) currentProgress.value -= 1;
};

// ================= Progress End =================

// ================= TAC Start =================

const tacCode = ref("");

const requestTAC = async () => {
	return await store
		.dispatch("register/requestTAC", {
			phoneNo: phoneNo.value,
			password: password.value,
		})
		.then(() => {
			toast.success(t("toast.tacSuccess"), toastOptionSuccess);
			startCountdown();
		})
		.catch(() => {
			toast.error(t("toast.tacFailed"), toastOptionError);
			startCountdown(5);
		});
};

const verifyTAC = async () => {
	return await store
		.dispatch("register/verifyTAC", {
			phoneNo: phoneNo.value,
			token: tacCode.value,
		})
		.then(() => {
			toast.success(t("toast.submitSuccess"), toastOptionSuccess);
			return true;
		})
		.catch(() => {
			toast.error(t("toast.tacVerificationFailed"), toastOptionError);
			return false;
		});
};

const requestForgotPassword = async () => {
	return await store
		.dispatch("member/forgotPassword", {
			phoneNo: phoneNo.value,
			byPassSms: true,
			tokenOTP: tacCode.value,
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			toast.error(t("toast.tacVerificationFailed"), toastOptionError);
			return false;
		});
};

const TAC_COOLDOWN = 60;
const timeRemaining = ref(0);

const startCountdown = async (cooldown) => {
	timeRemaining.value = cooldown || TAC_COOLDOWN;
	const timer = setInterval(() => {
		timeRemaining.value -= 1;
		if (timeRemaining.value <= 0) clearInterval(timer);
	}, 1000);
};

// ================= TAC End =================

// ================= Form Start =================

const countryCode = computed(() => countryData[country].COUNTRY_CODE);

const phoneNo = ref("");
const password = ref("");
const isMigrated = ref(false);

const onSubmitLogin = async () => {
	const validity = await v$.value.account.$validate();
	if (!validity) return;

	const loader = $loading.show();

	const status = await login();
	if (status?.loginStatus) {
		// insert success action here
		endModal();
	}
	if (!status?.loginStatus && status?.isPhoneConfirmError) {
		updateProgress("next");
		requestTAC();
	}

	if (!status?.loginStatus && status?.isMigrated) {
		isMigrated.value = true;
		updateProgress("next");
		requestTAC();
	}

	loader.hide();
};

const onSubmitTac = async () => {
	const validity = await v$.value.tac.$validate();
	if (!validity) return;

	const loader = $loading.show();

	if (isMigrated.value) {
		const result = await requestForgotPassword();
		if (result?.url) {
			endModal();
			window.open(result?.url, "_self");
		}
		loader.hide();
		return;
	}
	if (
		// prettier-ignore
		await verifyTAC()
	) {
		// insert success action here
		await login();
		endModal();
	}

	loader.hide();
};

const login = async () => {
	return await store
		.dispatch("identityServer/fetchLoginToken", {
			phoneNo: phoneNo.value,
			password: password.value,
		})
		.then((res) => {
			if (res?.loginStatus) toast.success(t("toast.loginSuccess"), toastOptionSuccess);
			if (!res?.loginStatus && !res?.isPhoneConfirmError && !res?.isMigrated)
				toast.error(t("toast.loginFailed"), toastOptionError);

			return res;
		})
		.catch((err) => {
			toast.error(t("toast.loginFailed"), toastOptionError);
		});
};

const clearForm = async () => {
	phoneNo.value = "";
	password.value = "";
	tacCode.value = "";

	await nextTick();
	v$.value.$reset();
};

// ================= Form End =================

// ================= Validation Start =================

const TAC_LENGTH = 6;

const rules = computed(() => ({
	account: {
		phoneNo: {
			required: helpers.withMessage(() => t("validation.required"), required),
			numeric: helpers.withMessage(() => t("validation.numeric"), numeric),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(countryData[country].MIN_PHONE_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(countryData[country].MAX_PHONE_LENGTH)
			),
		},
		password: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(authField.MIN_PASSWORD_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(authField.MAX_PASSWORD_LENGTH)
			),
		},
	},
	tac: {
		tacCode: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(TAC_LENGTH)
			),
		},
	},
}));

const v$ = useVuelidate(rules, {
	account: { phoneNo, password },
	tac: { tacCode },
});

// ================= Validation End =================

// ================= Modal Control Start =================

const MODAL_NAME = "login";
const showModal = ref(false);

const setModalParam = () => {
	router.replace({ query: { ...route.query, modal: MODAL_NAME } });
};

const unsetModalParam = () => {
	const { modal, ...params } = route.query;
	router.replace({ query: params });
};

watch(route, async (newRoute, oldRoute) => {
	if (newRoute.query?.modal != MODAL_NAME) {
		showModal.value = false;
		return;
	}
	await nextTick();
	if (!isLogin.value) showModal.value = true;
	else unsetModalParam();
});

const hideModal = () => {
	unsetModalParam();
	showModal.value = false;
};

const initModal = async () => {
	setModalParam();
	return;
};

const endModal = () => {
	currentProgress.value = 1;
	clearForm();
	hideModal();
	return;
};

// ================= Modal Control End =================
</script>

<style scoped lang="sass">
.buttonsContainer
	display: flex
	flex-direction: column
	gap: 1rem
	margin-top: 1rem
.forgotPasswordButton,
.backButton
	width: fit-content
	margin-inline: auto
	text-decoration: underline
.forgotPasswordButton
	color: var(--text-accent)
	font-weight: var(--font-bold)
.backButton
	color: var(--text-muted)

.registerContainer
	display: flex
	justify-content: space-between
	align-items: center
	gap: 0.5rem
	margin-bottom: 1.5rem
	width: 100%
	background: var(--background-primary-3)
	// box-shadow: var(--box-shadow-sm) inset
	border-radius: 0.5rem
	padding: 0.25rem
.registerMessage
	font-style: italic
	width: 50%
	text-align: center
.registerButton
	padding: 0.5rem
	background: var(--background-primary)
	// box-shadow: var(--box-shadow-sm)
	width: 50%
	border-radius: 0.25rem
</style>
