export default {
	RESPONSIBLEGAMING: "RESPONSIBLE GAMING",
	Description:
		"We want our players to have fun while playing at MOON99, so we encourage responsible gaming. We allow our players to define their own limits of responsible gaming, and then we help with management tools that allow you to set and control your own limits. Gambling is a form of entertainment, and should never place a burden on your life either financially or emotionally. Borrowing money to play, spending above your budget or using money allocated for other purposes is not only unwise but can lead to significant problems for you and others around you. We want you to enjoy playing on MOON99, so bet responsibly and have fun!",
	GettingAssistance: "Getting Assistance",
	Description1:
		"There are many organizations that can provide support and assistance to people who develop a problem with gambling, and we recommend that players contact a self-help organization for additional help.The following websites offer advice and guidance. Each one contains help line numbers and an e-mail address that you may contact if you would like confidential advice and support.",
	Description2:
		"<ul><li>www.gamblersanonymous.org</li><li>www.gamcare.org.uk</li><li>www.gamblingtherapy.org</li></ul>",
	Doyouhaveagamblingproblem: "Do you have a gambling problem?",
	Description3: "If you feel you may have a problem with your gambling, ask yourself the following questions:",
	DescGambling1:
		"<ul><li>Have others ever criticized your gambling?</li><li>Have you ever lied to cover up the amount of money or time you have spent gambling?</li><li>Do arguments, frustrations or disappointments make you want to gamble?</li><li>Do you gamble alone for long periods?</li><li>Do you stay away from work, college or school to gamble?</li><li>Do you gamble to escape from a boring or unhappy life?</li><li>Are you reluctant to spend 'gambling money' on anything else?</li><li>Have you lost interest in your family, friends or pastimes due to gambling?</li><li>After losing, do you feel you must try and win back your losses as soon as possible?</li><li>When gambling and you run out of money, do you feel lost and in despair, and need to gamble again as soon as possible?</li><li>Do you gamble until your last penny is gone?</li><li>Have you lied, stolen or borrowed just to get money to gamble or to pay gambling debts?</li><li>Do you feel depressed or even suicidal because of your gambling?</li></ul>",
	Description4:
		"The more you answer 'yes' to these questions, the more likely it is that you have a serious gambling problem and may wish to seek help and advice through the channels outlined above.",
	Managingyourplaying: "Managing your playing",
	Description5:
		"<ul><li>For players who enjoy gambling frequently, it is not uncommon to spend over their budget some of the time. We advise that specific budget plans be made to ensure your gambling expenditure is affordable.</li><li>Sometimes people deny the extent of their own problems and seek help only in crisis. Ask yourself honestly, and if you think you might have a problem set yourself the goal of stopping gambling for two weeks or a month as a test. If you are unable to achieve this, you could have a problem and might benefit from discussing your situation with a counselor.</li></ul>",
	Preventingunderagegambling: "Preventing under age gambling",
	Description6:
		"You must be at least 18 years old to play at MOON99. We undertake checks to ensure that no person under legal age has access to our gaming sites. Provision of inaccurate or dishonest information about a player's age will result in forfeiture of any winnings and may result in civil and/or criminal prosecution.",
};
