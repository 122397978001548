import { ref, computed } from "vue";
import store from "@/store/index";

const country = "SG";

const countryData = {
	MY: {
		CURRENCY: "RM",
		COUNTRY_CODE: "+60",
		MIN_PHONE_LENGTH: 9,
		MAX_PHONE_LENGTH: 10,
		EXAMPLE_PHONE_NUMBER: 123456789,
	},
	SG: {
		CURRENCY: "SGD",
		COUNTRY_CODE: "+65",
		MIN_PHONE_LENGTH: 8,
		MAX_PHONE_LENGTH: 9,
		EXAMPLE_PHONE_NUMBER: 12345678,
	},
};

const numberWithCommas = (number) => {
	if (number != undefined && number != null && !isNaN(number)) {
		const parts = number.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
		// return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	} else {
		return number;
	}
};

const limitDecimals = (num, min, max) => {
	return num.toFixed(Math.min(max, Math.max(min, (num.toString().split(".")[1] || []).length)));
};

const timezoneOffset = computed(() => store.getters["country/getTimezone"]);

const serverISOStringToDateGMT = (ISOString) => {
	// eg. from
	// 2024-03-27T16:40:33.057
	// to
	// Thu Mar 28 2024 00:40:33 GMT+0800 (Malaysia Time)
	return new Date(new Date(ISOString).getTime() + timezoneOffset.value * 60 * 60 * 1000);
};

const clientDateStringToServerISOString = (dateString: string, endOfDay = false): string => {
	// eg. from
	// 2024-03-27
	// to
	// 2024-03-26T16:00:00.000Z
	if (!endOfDay)
		return new Date(new Date(dateString).getTime() - timezoneOffset.value * 60 * 60 * 1000).toISOString();
	else
		return new Date(
			new Date(dateString).getTime() - (timezoneOffset.value - 24) * 60 * 60 * 1000 - 1
		).toISOString();
};

const clientTimeNow = () => {
	return new Date();
};

export {
	country,
	countryData,
	numberWithCommas,
	limitDecimals,
	serverISOStringToDateGMT,
	clientDateStringToServerISOString,
	clientTimeNow,
};
