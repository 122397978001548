<template>
	<b-modal
		id="register-modal"
		class="defaultModal"
		centered
		hide-footer
		:title="t('route.register')"
		v-model="showModal"
		@show="initModal"
		@hide="dal"
	>
		<div v-if="currentProgress == 1" class="loginContainer">
			<UnifiedLink :isModal="login.isModal" :modalName="login.modalName" :guestOnly="login.guestOnly">
				<button class="mainButton loginButton">
					{{ t(`route.${login.title}`) }}
				</button>
			</UnifiedLink>
			<div class="loginMessage">
				{{ t("common.alreadyAMember") }}
			</div>
		</div>

		<ProgressStep
			v-if="currentProgress > 1"
			:progressList="progressList"
			:currentProgress="currentProgress == 3 ? currentProgress : currentProgress - 1"
			class="registerProgress"
		/>
		<!-- Let progress stay in 1 during step 2. For visual purposes. -->

		<!-- ================= STEP 1 ================= -->

		<form v-if="currentProgress == 1" class="fieldContainerModal" @submit.prevent="onSubmitAccount" novalidate>
			<InputText
				v-model="fullName"
				:label="`${t('common.fullName')} (${t('common.asFullName')})`"
				:placeholder="'E.g. John Smith'"
				:required="true"
				:errors="v$.account.fullName.$errors"
			/>

			<InputPhone
				v-model="phoneNo"
				:countryCode="countryCode"
				:label="t('common.phoneNumber')"
				:required="true"
				:errors="v$.account.phoneNo.$errors"
			/>

			<InputEmail
				v-model="emailAddress"
				:label="t('common.email')"
				:required="true"
				:errors="v$.account.emailAddress.$errors"
			/>

			<InputPassword
				v-model="password"
				:label="t('common.password')"
				:required="true"
				:errors="v$.account.password.$errors"
			/>

			<InputPassword
				v-model="confirmPassword"
				:label="t('common.confirmPassword')"
				:required="true"
				:errors="v$.account.confirmPassword.$errors"
			/>

			<InputText
				v-if="isFeatureEnabled['REFERRAL']"
				v-model="referralCode"
				:label="t('common.referralCode')"
				:disabled="true"
			/>

			<div class="infoContainer" v-show="isVisiblePrivacy">
				<div class="titleContainer">
					{{ t(`category.privacypolicy`) }}
				</div>
				<PrivacyPolicyItem />
			</div>
			<div class="infoContainer" v-show="isVisibleTerms">
				<div class="titleContainer">
					{{ t(`category.terms`) }}
				</div>
				<TermsItem />
			</div>
			<InputCheckbox v-model="termsConsent" :required="true" :errors="v$.account.termsConsent.$errors">
				{{ t("common.termsConsent.iAmOver18") }}
				<a class="termsLink" @click.stop.prevent="toggleVisibility(1)">
					{{ t("route.terms") }}
				</a>

				{{ t("common.termsConsent.and") }}

				<a class="termsLink" @click.stop.prevent="toggleVisibility(2)">
					{{ t("route.privacypolicy") }}
				</a>
			</InputCheckbox>

			<div class="buttonsContainer">
				<button type="submit" class="mainButton">
					{{ t("common.next") }}
				</button>
			</div>
		</form>

		<!-- ================= STEP 1 ================= -->

		<!-- ================= STEP 2 ================= -->

		<form v-if="currentProgress == 2" class="fieldContainerModal" @submit.prevent="onSubmitTac" novalidate>
			<InputOtp
				v-model="tacCode"
				:label="t('common.verificationCode')"
				:required="true"
				:errors="v$.tac.tacCode.$errors"
			/>

			<div class="buttonsContainer">
				<!-- <button
            class="backButton unstyledButton"
            @click="updateProgress('prev')"
          >
            {{ t("common.back") }}
          </button> -->
				<!-- "key" directive helps refresh the ".once" event modifier -->
				<!-- once the coundown starts -->
				<button
					type="button"
					class="secondaryButton"
					:disabled="timeRemaining"
					@click.once="requestTAC(true)"
					:key="timeRemaining"
				>
					{{ timeRemaining ? t("common.resendInSeconds", { time: timeRemaining }) : t("common.resendTac") }}
				</button>

				<button type="submit" class="mainButton">
					{{ t("common.submit") }}
				</button>
			</div>
		</form>

		<!-- ================= STEP 2 ================= -->

		<!-- ================= STEP 3 ================= -->

		<div v-if="currentProgress == 3" class="registerSuccessContainer">
			<!-- <font-awesome-icon :icon="['far', 'circle-check']" class="successIcon" /> -->

			<div class="registerSuccess">
				{{ t("common.success") }}
			</div>

			<div class="registerSuccessMessage">
				{{ t("common.registerSuccess") }}
			</div>

			<div class="buttonsContainer">
				<button class="mainButton" data-bs-dismiss="modal" @click="endModal">
					{{ t("common.done") }}
				</button>
			</div>
		</div>

		<!-- ================= STEP 3 ================= -->

		<!-- ================= FOOTER ================= -->

		<!-- <template #footer> </template> -->

		<!-- ================= FOOTER ================= -->
	</b-modal>
</template>

<script setup>
import { ref, computed, nextTick, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import { useVuelidate } from "@vuelidate/core";
import { sameAs, minLength, maxLength, numeric, email, required, helpers } from "@vuelidate/validators";
import { isFeatureEnabled, routeData } from "@/constant";
import { country, countryData } from "@/composables/useCountry";
import { isLogin, authField } from "@/composables/useAuth";
import ProgressStep from "@/components/content/ProgressStep.vue";
import InputPhone from "@/components/dynamic/Inputs/InputPhone.vue";
import InputPassword from "@/components/dynamic/Inputs/InputPassword.vue";
import InputText from "@/components/dynamic/Inputs/InputText.vue";
import InputEmail from "@/components/dynamic/Inputs/InputEmail.vue";
import InputCheckbox from "@/components/dynamic/Inputs/InputCheckbox.vue";
import InputOtp from "@/components/dynamic/Inputs/InputOtp.vue";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import PrivacyPolicyItem from "@/components/content/Information/PrivacyPolicyItem.vue";
import TermsItem from "@/components/content/Information/TermsItem.vue";

const { t, te, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const $loading = useLoading();
const toast = useToast();

const registerModalName = "register-modal";

// ================= Links Start =================

const login = routeData.find((item) => item.title == "login");
const terms = routeData.find((item) => item.title == "terms");
const privacyPolicy = routeData.find((item) => item.title == "privacypolicy");

// ================= Links End =================

// ================= Progress Start =================

const currentProgress = ref(1);
const progressList = ref([
	{
		step: 1,
		name: t("common.accountSetup"),
	},
	{
		step: 2,
		name: t("common.tac"),
	},
	{
		step: 3,
		name: t("common.done"),
	},
]);
const updateProgress = (direction) => {
	if (direction == "next" && currentProgress.value < progressList.value.length) currentProgress.value += 1;
	if (direction == "prev" && currentProgress.value > 1) currentProgress.value -= 1;
};

// ================= Progress End =================

// ================= TAC Start =================

const tacCode = ref("");

const requestTAC = async () => {
	return await store
		.dispatch("register/requestTAC", {
			phoneNo: phoneNo.value,
			password: password.value,
		})
		.then(() => {
			toast.success(t("toast.tacSuccess"), toastOptionSuccess);
			startCountdown();
		})
		.catch(() => {
			toast.error(t("toast.tacFailed"), toastOptionError);
			startCountdown(5);
		});
};

const verifyTAC = async () => {
	return await store
		.dispatch("register/verifyTAC", {
			phoneNo: phoneNo.value,
			token: tacCode.value,
		})
		.then(() => {
			toast.success(t("toast.submitSuccess"), toastOptionSuccess);
			return true;
		})
		.catch(() => {
			toast.error(t("toast.tacVerificationFailed"), toastOptionError);
			return false;
		});
};

const TAC_COOLDOWN = 60;
const timeRemaining = ref(0);

const startCountdown = async (cooldown) => {
	timeRemaining.value = cooldown || TAC_COOLDOWN;
	const timer = setInterval(() => {
		if (timeRemaining.value > 0) timeRemaining.value -= 1;
		if (timeRemaining.value <= 0) clearInterval(timer);
	}, 1000);
};

const resetTAC = () => {
	timeRemaining.value = 0;
	tacCode.value = "";
};

// ================= TAC End =================

// ================= Form Start =================

const countryCode = computed(() => countryData[country].COUNTRY_CODE);

const fullName = ref("");
const phoneNo = ref("");
const emailAddress = ref("");
const password = ref("");
const confirmPassword = ref("");
const referralCode = ref(route.params?.referralCode || "");
const termsConsent = ref(false);

watch(route, (newRoute) => {
	if (newRoute.query?.referralCode && isFeatureEnabled["REFERRAL"]) referralCode.value = newRoute.query?.referralCode;
});

const formPreprocess = () => {
	fullName.value = fullName.value?.trim();
};

const onSubmitAccount = async () => {
	formPreprocess();

	const validity = await v$.value.account.$validate();
	if (!validity) return;

	const loader = $loading.show();

	if (
		// prettier-ignore
		await registerAccount()
	) {
		// insert success action here
		updateProgress("next");
		requestTAC();
	}
	loader.hide();
};

const registerAccount = async () => {
	return await store
		.dispatch("register/registerAccount", {
			fullName: fullName.value,
			phoneNo: phoneNo.value,
			email: emailAddress.value,
			password: password.value,
			confirmPassword: confirmPassword.value,
			referralCode: isFeatureEnabled["REFERRAL"] ? referralCode.value : "",
			countryCode: countryCode.value,
		})
		.then(() => {
			toast.success(t("toast.registerSuccess"), toastOptionSuccess);
			return true;
		})
		.catch((err) => {
			const error = err?.response.data.errors?.[""]?.[0];
			if (error) {
				const errorMsg = te(`error.${error || ""}`) ? t(`error.${error || ""}`) : error || "";
				toast.error(t("toast.submitFailed", { error: errorMsg }), toastOptionError);
			} else toast.error(t("toast.submitFailed", { error: err?.response.data.title }), toastOptionError);
		});
};

const onSubmitTac = async () => {
	const validity = await v$.value.tac.$validate();
	if (!validity) return;

	const loader = $loading.show();

	if (
		// prettier-ignore
		await verifyTAC()
	) {
		// insert success action here
		updateProgress("next");
		onLogin();
	}

	loader.hide();
};

const onLogin = async () => {
	await store
		.dispatch("identityServer/fetchLoginToken", {
			phoneNo: phoneNo.value,
			password: password.value,
		})
		.catch((err) => {
			toast.error(t("toast.loginFailed"), toastOptionError);
		});
};

const clearForm = async () => {
	fullName.value = "";
	phoneNo.value = "";
	emailAddress.value = "";
	password.value = "";
	confirmPassword.value = "";
	referralCode.value = "";
	termsConsent.value = false;
	resetTAC();

	await nextTick();
	v$.value.$reset();
};

// ================= Form End =================

// ================= Validation Start =================

const FULL_NAME_PATTERN = /^(?!.* {2})(?!.*--)(?!.*[^a-zA-Z]-[^a-zA-Z])[a-zA-Z]+(?:[ -][a-zA-Z]+)*$/;
const ACCOUNT_NAME_MIN_LENGTH = 5;
const ACCOUNT_NAME_MAX_LENGTH = 100;
const TAC_LENGTH = 6;

const rules = computed(() => ({
	account: {
		fullName: {
			required: helpers.withMessage(() => t("validation.required"), required),
			regex: helpers.withMessage(() => t("validation.fullNameInvalid"), helpers.regex(FULL_NAME_PATTERN)),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(ACCOUNT_NAME_MIN_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(ACCOUNT_NAME_MAX_LENGTH)
			),
		},
		phoneNo: {
			required: helpers.withMessage(() => t("validation.required"), required),
			numeric: helpers.withMessage(() => t("validation.numeric"), numeric),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(countryData[country].MIN_PHONE_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(countryData[country].MAX_PHONE_LENGTH)
			),
		},
		emailAddress: {
			required: helpers.withMessage(() => t("validation.required"), required),
			email: helpers.withMessage(() => t("validation.email"), email),
		},
		password: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(authField.MIN_PASSWORD_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(authField.MAX_PASSWORD_LENGTH)
			),
			sameAs: helpers.withMessage(() => t("validation.passwordNotMatch"), sameAs(confirmPassword.value)),
		},
		confirmPassword: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(authField.MIN_PASSWORD_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(authField.MAX_PASSWORD_LENGTH)
			),
			sameAs: helpers.withMessage(() => t("validation.passwordNotMatch"), sameAs(password.value)),
		},
		termsConsent: {
			sameAs: helpers.withMessage(() => t("validation.pleaseAgree"), sameAs(true)),
		},
	},
	tac: {
		tacCode: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(TAC_LENGTH)
			),
		},
	},
}));

const v$ = useVuelidate(rules, {
	account: { fullName, phoneNo, emailAddress, password, confirmPassword, termsConsent },
	tac: { tacCode },
});

// ================= Validation End =================

// ================= Modal Control Start =================

// Show&Hide register modal terms and privacy
const isVisibleTerms = ref(false);
const isVisiblePrivacy = ref(false);

const toggleVisibility = (show) => {
	if (show === 1) {
		isVisibleTerms.value = !isVisibleTerms.value;
		isVisiblePrivacy.value = false;
	} else if (show === 2) {
		isVisiblePrivacy.value = !isVisiblePrivacy.value;
		isVisibleTerms.value = false;
	}
};

const MODAL_NAME = "register";
const showModal = ref(false);

const setModalParam = () => {
	router.replace({ query: { ...route.query, modal: MODAL_NAME } });
};

const unsetModalParam = () => {
	const { modal, ...params } = route.query;
	router.replace({ query: params });
};

const hideModal = () => {
	unsetModalParam();
	showModal.value = false;
};
const dal = () => {
	unsetModalParam();
	showModal.value = false;
};

watch(route, async (newRoute, oldRoute) => {
	if (newRoute.query?.modal != MODAL_NAME) {
		showModal.value = false;
		return;
	}
	await nextTick();
	if (!isLogin.value) showModal.value = true;
	else unsetModalParam();
});

const initModal = async () => {
	setModalParam();
	return;
};

const endModal = () => {
	if (currentProgress.value == progressList.value.length) {
		currentProgress.value = 1;
		clearForm();
	}
	return;
};

// ================= Modal Control End =================
</script>

<style scoped lang="sass">
.registerProgress
	margin-block: 0.5rem 1rem
:deep(.progressStepList)
	position: relative
	&::before
		content: ''
		position: absolute
		top: -3px
		left: 10px
		border-radius: 999rem
		width: calc(100% - 20px)
		height: 30px
		// background: var(--gradient-secondary-reversed)
		// box-shadow: var(--box-shadow-sm) inset
:deep(.progressIcon)
	background: var(--border-regular)
	color: var(--text-regular)
	// box-shadow: var(--box-shadow-sm)
:deep(.progressStep:not(:last-child)::after)
	background: var(--border-regular)
:deep(.progressStep.active::after)
	background: var(--text-accent)
:deep(.progressStep.active .progressIcon)
	background: var(--color-accent)
	color: var(--text-regular)
// :deep(.progressName)
// 	position: absolute
// 	top: calc(100% + 0.25rem)

.buttonsContainer
	display: flex
	flex-direction: column
	gap: 1rem
	margin-top: 1rem
	width: 100%
// .backButton
//   width: fit-content
//   margin-inline: auto
//   text-decoration: underline
//   color: var(--text-muted)
.termsLink
	color: var(--text-accent)
	cursor: pointer
.registerSuccessContainer
	display: flex
	flex-direction: column
	align-items: center
	gap: 1rem
.successIcon
	font-size: 7rem
	color: var(--text-accent)
	animation-name: pop
	animation-delay: 0.5s
	animation-duration: 5s
.registerSuccess
	color: var(--text-accent)
	font-weight: var(--font-bold)
	font-size: 3em
.infoContainer
	width: auto
	height: 15rem
	overflow: auto
	color: var(--text-light)
	background: var(--input-background)
	border: none
	border: 1px solid var(--input-border-regular)
	padding-inline: 0.5rem
	outline: none
	border-radius: 0.25rem
.titleContainer
	margin-block: 1rem
	font-size: 1.2em
	font-weight: var(--font-bold)
	text-transform: uppercase
	padding-inline: 0.5rem

.loginContainer
	display: flex
	justify-content: space-between
	align-items: center
	gap: 0.5rem
	margin-bottom: 1.5rem
	width: 100%
	background: var(--background-primary-3)
	// box-shadow: var(--box-shadow-sm) inset
	border-radius: 0.5rem
	padding: 0.25rem
.loginMessage
	font-style: italic
	width: 50%
	text-align: center
.loginButton
	padding: 0.5rem
	background: var(--background-primary)
	// box-shadow: var(--box-shadow-sm)
	width: 50%
	border-radius: 0.25rem
</style>
