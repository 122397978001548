export default {
	RESPONSIBLEGAMING: "TANGGUNGJAWAB PERMAINAN",
	Description:
		"Kami mahu pemain kami berseronok bermain di MOON99, jadi kami menggalakkan permainan yang bertanggungjawab. Kami membenarkan pemain kami menentukan had permainan mereka yang bertanggungjawab, dan kemudian kami membantu dengan alat pengurusan yang membolehkan anda menetapkan dan mengawal batas anda sendiri. Perjudian adalah satu bentuk hiburan, dan tidak boleh meletakkan beban pada kehidupan anda sama ada dari segi kewangan atau emosi. Meminjam wang untuk bermain, perbelanjaan di atas belanjawan anda atau menggunakan wang yang diperuntukkan untuk tujuan lain bukan sahaja tidak bijak tetapi boleh membawa kepada masalah penting bagi anda dan orang lain di sekeliling anda. Kami mahu anda menikmati prmainan di MOON99, jadi taruhan bertanggungjawab dan bersenang-senang!",
	GettingAssistance: "Mendapat Bantuan",
	Description1:
		"Terdapat banyak organisasi yang boleh memberikan sokongan dan bantuan kepada orang-orang yang mengalami masalah dengan perjudian, dan kami mengesyorkan agar pemain menghubungi organisasi bantuan diri untuk bantuan tambahan. Laman web berikut menawarkan nasihat dan bimbingan. Setiap satunya mengandungi nombor talian bantuan dan alamat e-mel yang anda boleh hubungi jika anda ingin nasihat dan sokongan rahsia.",
	Description2:
		"<ul><li>www.gamblersanonymous.org</li><li>www.gamcare.org.uk</li><li>www.gamblingtherapy.org</li></ul>",
	Doyouhaveagamblingproblem: "Adakah anda mempunyai masalah perjudian?",
	Description3:
		"Jika anda rasa anda mungkin menghadapi masalah dengan perjudian anda, tanya diri anda soalan berikut:",
	DescGambling1:
		"<ul><li>Ada yang pernah mengkritik perjudian anda?</li><li>Pernahkah anda berbohong untuk menutupi jumlah wang atau masa yang telah anda habiskan berjudi?</li><li>Adakah argumen, frustrasi atau kekecewaan membuat anda mahu berjudi?</li><li>Adakah anda memang berjudi sahaja untuk tempoh yang panjang?</li><li>Adakah anda menjauh dari kerja, kolej atau sekolah untuk berjudi?</li><li>Adakah anda berjudi untuk melarikan diri dari kehidupan yang membosankan atau tidak bahagia?</li><li>Adakah anda enggan membelanjakan 'wang perjudian' dengan apa-apa lagi?</li><li>Adakah anda kehilangan minat dalam keluarga, sahabat atau masa lapang anda akibat perjudian?</li><li>Selepas kalah, adakah anda merasakan anda perlu mencuba dan memenangi kerugian anda secepat mungkin?</li><li>Apabila perjudian dan anda kehabisan wang, adakah anda merasa hilang dan berputus asa, dan perlu berjudi lagi secepat mungkin?</li><li>Adakah anda berjudi sehingga senat terakhir anda hilang?</li><li>Adakah anda telah berbohong, dicuri atau dipinjam hanya untuk mendapatkan wang untuk berjudi atau membayar hutang perjudian?</li><li>Adakah anda berasa tertekan atau bunuh diri kerana perjudian anda?</li></ul>",
	Description4:
		"Semakin banyak anda menjawab 'ya' kepada soalan-soalan ini, kemungkinan besar anda mempunyai masalah perjudian yang serius dan mungkin meminta bantuan dan nasihat melalui saluran yang digariskan di atas.",
	Managingyourplaying: "Menguruskan permainan anda",
	Description5:
		"<ul><li>Bagi pemain yang sering menikmati perjudian, ia tidak jarang menghabiskan belanjawan mereka beberapa kali. Kami menasihatkan pelan belanjawan tertentu untuk memastikan perbelanjaan perjudian anda mampu dimiliki.</li><li>Kadang-kadang orang menyangkal sejauh mana masalah mereka sendiri dan mencari bantuan hanya dalam krisis. Tanyalah diri anda dengan jujur, dan jika anda menganggap anda mungkin mempunyai masalah menetapkan matlamat menghentikan judi selama dua minggu atau sebulan sebagai ujian. Jika anda tidak dapat mencapai ini, anda mungkin menghadapi masalah dan mungkin mendapat manfaat daripada membincangkan situasi anda dengan kaunselor.</li></ul>",
	Preventingunderagegambling: "Mencegah perjudian di bawah umur",
	Description6:
		"Anda mestilah berumur sekurang-kurangnya 18 tahun untuk bermain di MOON99. Kami melakukan pemeriksaan untuk memastikan tiada sesiapa di bawah umur yang sah mempunyai akses kepada laman web kami. Peruntukan maklumat yang tidak tepat atau tidak jujur tentang umur pemain akan menyebabkan pelucuthakan mana-mana kemenangan dan boleh mengakibatkan pendakwaan sivil dan / atau jenayah.",
};
