import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";

export interface GlobalSettingStateInterface {
	globalSetting: GlobalSettingResponse[];
	currentTime: string;
}

function state(): GlobalSettingStateInterface {
	return {
		globalSetting: [],
		currentTime: "",
	};
}

export default state;
